import React, { useEffect, useReducer, useState } from 'react';

import { useService } from 'rc-service';
import { ShowOf } from 'react-showof';
import cn from 'clsx';

import {
  typographyDisplayLargeBold,
  typographyDisplaySmallBold,
  typographyHeadlineMediumRegular,
  typographyHeadlineSmallRegular,
} from '@agroop/ui/lib/mdc';
import { I18nService } from '@agroop/common/services';
import playImage from './assets/play.png';
import awardImage from './assets/award.png';
import logoImg from './assets/logo.png';
import bandidosDe4PatasGif from './assets/bandidos_de_4_patas.gif';
import baterAsBotasGif from './assets/bater_as_botas.gif';
import ghostingGif from './assets/ghosting.gif';
import maisRapidosQueOInemGif from './assets/mais_rapidos_que_o_inem.gif';
import maisTempoParaAturarOArturGif from './assets/mais_tempo_para_aturar_o_artur.gif';
import naoHaCaPaoParaMalucosGif from './assets/nao_ha_ca_pao_para_malucos.gif';
import oObikweluDosSoftwaresGif from './assets/o_obikwelu_dos_softwares.gif';
import paraQueComplicarGif from './assets/para_que_complicar.gif';
import tudoEMaisUmParDeBotasGif from './assets/tudo_e_mais_um_par_de_botas.gif';
import vidaMaisAborrecidaGif from './assets/vida_mais_aborrecida.gif';
import { useMediaQuery } from '../../utils';
import styles from './BgVideo.module.scss';

export type AvailableGifs = (typeof gifs)[number];

export const gifs = [
  'VIDA_MAIS_ABORRECIDA',
  'BANDIDOS_DE_4_PATAS',
  'BATER_AS_BOTAS',
  'GHOSTING',
  'MAIS_RAPIDOS_QUE_O_INEM',
  'MAIS_TEMPO_PARA_ATURAR_O_ARTUR',
  'NAO_HA_CA_PAO_PARA_MALUCOS',
  'O_OBIKWELU_DOS_SOFTWARES',
  'PARA_QUE_COMPLICAR',
  'TUDO_E_MAIS_UM_PAR_DE_BOTAS',
] as const;

const gifMap: Record<AvailableGifs, string> = {
  VIDA_MAIS_ABORRECIDA: vidaMaisAborrecidaGif,
  BANDIDOS_DE_4_PATAS: bandidosDe4PatasGif,
  BATER_AS_BOTAS: baterAsBotasGif,
  GHOSTING: ghostingGif,
  MAIS_RAPIDOS_QUE_O_INEM: maisRapidosQueOInemGif,
  MAIS_TEMPO_PARA_ATURAR_O_ARTUR: maisTempoParaAturarOArturGif,
  NAO_HA_CA_PAO_PARA_MALUCOS: naoHaCaPaoParaMalucosGif,
  O_OBIKWELU_DOS_SOFTWARES: oObikweluDosSoftwaresGif,
  PARA_QUE_COMPLICAR: paraQueComplicarGif,
  TUDO_E_MAIS_UM_PAR_DE_BOTAS: tudoEMaisUmParDeBotasGif,
};

const youtubeVideoMap: Record<AvailableGifs, string> = {
  VIDA_MAIS_ABORRECIDA: 'https://www.youtube.com/embed/n6tFjVu5zZM',
  BANDIDOS_DE_4_PATAS: 'https://www.youtube.com/embed/dcnbRvurhZo',
  BATER_AS_BOTAS: 'https://www.youtube.com/embed/IWE761KJdgo',
  GHOSTING: 'https://www.youtube.com/embed/fRbXWCNv8wk',
  MAIS_RAPIDOS_QUE_O_INEM: 'https://www.youtube.com/embed/-rsxW5bhfEs',
  MAIS_TEMPO_PARA_ATURAR_O_ARTUR: 'https://www.youtube.com/embed/QWRBdmH2EV8',
  NAO_HA_CA_PAO_PARA_MALUCOS: 'https://www.youtube.com/embed/xH30ls6nHys',
  O_OBIKWELU_DOS_SOFTWARES: 'https://www.youtube.com/embed/Jo4jWX1cpoI',
  PARA_QUE_COMPLICAR: 'https://www.youtube.com/embed/kMqedVbaa94',
  TUDO_E_MAIS_UM_PAR_DE_BOTAS: 'https://www.youtube.com/embed/5PKJGKXRL98',
};

const shuffleArray = <T extends readonly string[]>(array: T): T => {
  const shuffled = [...array] as string[];

  // eslint-disable-next-line no-plusplus
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled as any;
};
export default function BgVideo() {
  const [shuffledGifs, setShuffledGifs] = useState<typeof gifs>(gifs);
  const [isSmall] = useMediaQuery(matchMedia(`(max-width: 930px)`));
  const [breakpoint] = useMediaQuery(matchMedia(`(max-width: 1180px)`));

  const { t } = useService(I18nService);

  const [show, toggle] = useReducer(x => !x, false);
  const [index, setIndex] = useState(0);

  const handleDotClick = (i: number) => setIndex(i);

  useEffect(() => {
    setShuffledGifs(shuffleArray(gifs));
  }, []);

  const currentGifKey = shuffledGifs[index];

  return (
    <>
      <div className={styles.wrapper}>
        {!isSmall && (
          <>
            <div className={styles.logoContainer}>
              <img src={logoImg} alt="Agroop" />
            </div>
            <div className={styles.titleContainer}>
              <h1 className={cn(styles.title, breakpoint ? typographyDisplaySmallBold : typographyDisplayLargeBold)}>
                {t('uncomplicatedIrrigation', '')}
              </h1>
              <hr className={`${styles.line} m-b-16`} />
            </div>
            <div className={styles.gifWrapper}>
              <div className={styles.gifContainer}>
                <img className={styles.gif} src={gifMap[currentGifKey]} alt="" />
                <img className={styles.awardImage} src={awardImage} alt="Empreendedorismo e inovação" />
                <img className={styles.playButton} src={playImage} alt="" onClick={toggle} />
              </div>
              <span className={cn(styles.phrase, breakpoint ? typographyHeadlineSmallRegular : typographyHeadlineMediumRegular)}>
                {t.c(`gifPhrases.${currentGifKey}`, [<span />])}
              </span>
              <div className={styles.dots}>
                {shuffledGifs.map((_, i) => (
                  <span key={i} className={styles.dot} aria-current={i === index} onClick={() => handleDotClick(i)} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <>{show && !isSmall && <ShowOf when={show} render={VideoPopup} src={youtubeVideoMap[currentGifKey]} onClose={toggle} />}</>
    </>
  );
}

interface VideoPopupProps {
  src: string;
  onClose: () => void;
}

const VideoPopup = ({ src, onClose }: VideoPopupProps) => {
  return (
    <div onClick={onClose} className={styles.videoWrapper}>
      <iframe className={styles.iframe} allowFullScreen src={src} title="Youtube video" />
    </div>
  );
};
